import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import VCalendar from 'v-calendar';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueGoogleMaps from '@fawmi/vue-google-maps'
import axios from 'axios';
import Toaster from '@meforma/vue-toaster';
import Shimmer from 'vue3-shimmer';
// import Swiper from 'swiper/vue';
axios.defaults.withCredentials = false
axios.defaults.baseURL = 'https://admin.badmintonsouq.com/'
createApp(App).use(store).use(router).use(Shimmer).use(Toaster).use(VueSweetalert2).use(VCalendar, {
    componentPrefix: 'vc',
  }).use(VueGoogleMaps, {
    load: {
      key: "AIzaSyC9K84fNw6iBAmoUa3OD_w4YOqDRNx7Tvo",
      libraries: "places"
    }
  }).mount('#app')
  
axios.interceptors.response.use(undefined, function (error) {
    if (error) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {

            originalRequest._retry = true;
            store.dispatch('LogOut')
            return router.push('/login')
        }
    }
})
