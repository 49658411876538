<template>
  <div class="page-loader">
    <!-- <div class="spinner-grow text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div> -->
    <!-- <div class="logo-loader">
      <img src=".././assets/logo.png" class="logo-icon" alt="">
    </div> -->
    <!-- <div>{{text}}</div> -->
    <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
  </div> 
</template>

<script>
  export default {
    props: {
      text: {
        required: false,
        type: String,
        default: 'Loading',
      }
    }
    // data: () => {
    //   return {
    //     isloaded: false
    //   }
    // },
    // mounted() {
    //   document.onreadystatechange = () => {
    //     if (document.readyState == "complete") { 
    //       this.isloaded = true;
    //     } 
    //   }
    // },
  }
</script>

<style scoped>
   /* $colors: #8CC271, #69BEEB, #F5AA39, #E9643B; */
  .page-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    z-index: 999;
  }
  .logo-icon {
    width: 150px;
    margin: 0 auto;
    animation-name: stretch;
    animation-duration: 0.8s; 
    animation-timing-function: ease-out; 
    animation-delay: 0;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
  }

  @keyframes stretch {
    0% {
      transform: scale(.8);
    }
    100% {
      transform: scale(1.3);
    }
  }
  /* .page-loader .text-primary{
    color: #000052 !important;
  } */
  .lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #000052;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>