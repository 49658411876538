<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    
     <!-- :slidesPerGroup="1" 
     :loop="true" 
     :loopFillGroupWithBlank="true"  -->
  <div class="banner-slider">
    
    <free-style-shimmer
      :is-loading="showLoading"
      height="300px"
      width="100%"
      border-radius="0"
      color="#bdbdbd"
    />
    <!-- <h1>{{showLoading}}</h1> -->
    <swiper
     :spaceBetween="30"  
     :slidesPerView="1" 
     :centeredSlides="true" 
     :autoplay='{
      "delay": 2500,
      "disableOnInteraction": false
      }' 
      :pagination='{
        "clickable": true
      }'
      :navigation="false" class="main-banner"> 
    <swiper-slide 
     v-for="slide in slides"
     :key="slide"
    >
    <div class="carousel-item-single">
      <!-- <img :src="base_url+slide.web_image" /> -->
      <picture>
        <source media="(min-width:650px)" :srcset="base_url+slide.web_image">
        <!-- Provide image for Mobile banner -->
        <img :src="base_url+slide.mobile_image" :alt="slide.title" >
      </picture>
      <div class="banner-text-area">
        <h1 class="banner-text">{{slide.title}}</h1>
        <a :href="slide.web_url" class="link-btn">Explore<i class='bx bx-right-arrow-alt'></i></a>
      </div>
    </div>
    </swiper-slide>
  </swiper>
  <!-- <h1>{{pagination.renderBullet}}</h1> -->
  </div>
  <div class="shop-departments">
    <div class="container">
      <div class="top-sec">
        <h1 class="title">BADMINTON SOUQ - SHOP BY PRODUCTS.</h1>
        <!--<a href="#" class="link-btn">Explore All<i class='bx bx-right-arrow-alt'></i></a>-->
      </div>
      
      <ul class="categories" v-if="showLoading">
        <li class="item">
              <free-style-shimmer
                :is-loading="showLoading"
                height="100px"
                width="100px"
                border-radius="10px"
                color="#bdbdbd"
              />
        </li>
        <li class="item">
              <free-style-shimmer
                :is-loading="showLoading"
                height="100px"
                width="100px"
                border-radius="10px"
                color="#bdbdbd"
              />
        </li>
        <li class="item">
              <free-style-shimmer
                :is-loading="showLoading"
                height="100px"
                width="100px"
                border-radius="10px"
                color="#bdbdbd"
              />
        </li>
        <li class="item">
              <free-style-shimmer
                :is-loading="showLoading"
                height="100px"
                width="100px"
                border-radius="10px"
                color="#bdbdbd"
              />
        </li>
        <li class="item">
              <free-style-shimmer
                :is-loading="showLoading"
                height="100px"
                width="100px"
                border-radius="10px"
                color="#bdbdbd"
              />
        </li>
        <li class="item">
              <free-style-shimmer
                :is-loading="showLoading"
                height="100px"
                width="100px"
                border-radius="10px"
                color="#bdbdbd"
              />
        </li>
        <li class="item">
              <free-style-shimmer
                :is-loading="showLoading"
                height="100px"
                width="100px"
                border-radius="10px"
                color="#bdbdbd"
              />
        </li>
        <li class="item">
              <free-style-shimmer
                :is-loading="showLoading"
                height="100px"
                width="100px"
                border-radius="10px"
                color="#bdbdbd"
              />
        </li>
        <li class="item">
              <free-style-shimmer
                :is-loading="showLoading"
                height="100px"
                width="100px"
                border-radius="10px"
                color="#bdbdbd"
              />
        </li>
      </ul>
      <ul class="categories">
        <li class="item" v-for="category in categories" :key="category">
          <div class="img-box">
            <a :href="$router.resolve({path: '/category/'+category.slug}).href" class="link">
              <img :src="base_url+category.image" alt="">
            </a>
          </div>
          <p class="name">{{category.title}}</p>
        </li>
      </ul>
      
      <ul class="services-categories">
        <li class="item">
          <div class="img-box">
            <!-- data-bs-toggle="modal" data-bs-target="#StringingModal" -->
              <a v-if="token != null" href="/bat-string" class="link"></a>
              <a v-else href="#" data-bs-toggle="modal" data-bs-target="#AuthModal" class="link"></a>
              <img src="../assets/bat_stringing.svg" alt="">
              <p class="cname">Bat Stringing</p>
          </div>
        </li>
        <li class="item" v-if="!showLoading">
          <div class="img-box">
              <a href="#" class="link" data-bs-toggle="modal" data-bs-target="#RepairModal"></a>
              <img src="../assets/bat_repair.svg" alt="">
              <p class="cname">Bat Repair</p>
          </div>
        </li>
        <li class="item">
          <div class="img-box">
              <a href="/coaching-centres" class="link"></a>
              <img src="../assets/coaching_center.svg" alt="">
              <p class="cname">Courts & Academies</p>
          </div>
        </li>
        <li class="item">
          <div class="img-box">
              <a href="/personal-trainers" class="link"></a>
              <img src="../assets/personal_training.svg" alt="">
              <p class="cname">Personal Trainers</p>
          </div>
        </li>
        <li class="item">
          <div class="img-box">
              <a href="/polling-agents" class="link"></a>
              <img src="../assets/queuing.svg" alt="">
              <p class="cname">Queuing/Polling</p>
          </div>
        </li>
        <li class="item">
          <div class="img-box">
              <a href="/organize" class="link"></a>
              <img src="../assets/noun_Trophy_3878553@2x.png" alt="">
              <p class="cname">Tournament Organizers</p>
          </div>
        </li>
        <li class="item">
          <div class="img-box">
              <!-- <a href="/sports-clinics-doctors" class="link"></a> -->

              <img src="../assets/pre-owned.png" alt="">
              <p class="cname">Pre Owned <br> Comming Soon ...</p>
              
          </div>
        </li>
        <li class="item">
          <div class="img-box">
              <a href="#" class="link" data-bs-toggle="modal" data-bs-target="#CourtModal"></a>
              <img src="../assets/court_construction.svg" alt="">
              <p class="cname">Court Construction</p>
          </div>
        </li>
        <li class="item">
          <div class="img-box">
              <a href="/events" class="link"></a>
              <img src="../assets/noun_BadmintonCourt_198230@2x.png" alt="">
              <p class="cname">Events</p>
          </div> 
        </li> 
        <!--<li class="item">
          <div class="img-box">
              <a href="#" class="link"></a>
              <img src="../assets/noun_stadium_1174204.png" alt="">
              <p class="cname">Booking Venues</p>
          </div>
        </li>-->
      </ul>
    </div>
  </div>
  <HotProduct :Listdata="hotProducts" :loadingdata ="showLoading"/>
  <div class="collections">
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <div class="img-wrap">
            <img src="../assets/mens.jpg" alt="" class="image">
            <div class="content">
              <h3 class="cate-name">MENS</h3>
              <a :href="$router.resolve({path: '/main-category/mens'}).href" class="link-btn">Shop Collection <i class="bx bx-right-arrow-alt"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="title-sec">
            <h2 class="text">COL
                -LECTION.</h2>
          </div>
          <div class="img-wrap">
            <img src="../assets/women.jpg" alt="" class="image">
            <div class="content">
              <h3 class="cate-name">WOMEN</h3>
              <a :href="$router.resolve({path: '/main-category/women'}).href" class="link-btn">Shop Collection <i class="bx bx-right-arrow-alt"></i></a>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          
          <div class="img-wrap">
            <img src="../assets/brands.jpg" alt="" class="image">
            <div class="content">
              <h3 class="cate-name">BRANDS</h3>
              <router-link class="link-btn" to="/brands">Shop Collection<i class="bx bx-right-arrow-alt"></i></router-link>
            </div>
          </div>
          
          <div class="img-wrap">
            <img src="../assets/junior.jpg" alt="" class="image">
            <div class="content">
              <h3 class="cate-name">JUNIOR</h3>
              <a :href="$router.resolve({path: '/main-category/junior'}).href" class="link-btn">Shop Collection <i class="bx bx-right-arrow-alt"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bestselling">
  <!-- <div class="bestselling"  v-if="bestSelling"> -->
    <div class="container">
      <div class="top-sec">
        <h3 class="title">BEST SELLING.</h3>
      </div>
    </div>
    <ProductCarousel :Listdata="bestSelling" :loadingdata ="showLoading"/>
  </div>
  <div class="brand-carousel">
    <!--<brandsCarousel :Listdata="brands"/>-->
  </div>
  <!-- <div id="Resyed"
     v-for="product in products"
     :key="product.id"
     >
     {{product}}
     </div> -->
  </div>

<!-- Modal -->
<div class="modal fade auth-modal organize-modal" id="RepairModal" tabindex="-1" aria-labelledby="OrganizeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="AuthModalLabel">Bat Repair</h5>
        <button type="button" class="btn-close" id="RepairModalClose" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
            <div class="login-form">
              <form @submit.prevent="submitBatRepairForm">
                <p id="error">{{error_message}}</p>
                <div class="row form-group" v-if="token == null">
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Name" v-model="form.name">
                  </div>
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Phone" v-model="form.phone">
                  </div>
                </div>
                <div class="mb-3 icon-input" v-if="token == null">
                  <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Email" v-model="form.email">
                </div>
                <div class="mb-3 icon-input">
                  <GMapAutocomplete
                  placeholder="Type your location"
                  @place_changed="setPlaces"
                  class="form-control"
                  :value = "form.location"
                  :options="{
                      componentRestrictions: {'country': ['ae']}
                  }"
                  >
                  </GMapAutocomplete>
                  <i class='icon bx bx-current-location'></i>
                </div>
                <!--<div class="row form-group">
                  <div class="mb-3 col-md-6">
                    <select class="form-select" aria-label="Default select example">
                      <option selected>Brand</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                  <div class="mb-3 col-md-6">
                    <select class="form-select" aria-label="Default select example">
                      <option selected>String Type</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>
                <div class="row form-group">
                  <div class="mb-3 col-md-6">
                    <select class="form-select" aria-label="Default select example">
                      <option selected>Color</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                  <div class="mb-3 col-md-6">
                    <select class="form-select" aria-label="Default select example">
                      <option selected>Tension</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>-->
                <div class="mb-3 icon-input" v-if="form.image">
                  <img :src="form.imageDisplay" height="150" alt=""/>
                </div>
                <div class="mb-3 icon-input">
                  <label for="FormControlFileUp" class="custom-file-upload">
                    <input type="hidden" @click='pickFile' v-model='form.imageName'>
                    <input type="file" style="display: none"
                    ref="image"
                    accept="image/*"
                    @change="onFilePicked" class="form-control" id="FormControlFileUp" placeholder="Upload racket Photo">
                    <i class='icon bx bx-upload'></i><span class="placeholder-text">Upload racket Photo</span>
                  </label>
                </div>
                <div class="mb-3">
                  <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="comments..." rows="4" v-model="form.comments"></textarea>
                </div>
                  <!--<p class="text-foot">Free Grip Change for Pro 65 /BG 65 string</p>-->
                <!--<a href="#" data-bs-toggle="modal" data-bs-target="#SuccessModal">-->
                <button class="btn btn-primary login-btn" type="submit">
                  <span v-if="!showLoad">
                    Submit <i class="bx bx-right-arrow-alt"></i>
                  </span>
                  <div v-if="showLoad" class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
                <!--</a>-->
              </form>
            </div>
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade auth-modal organize-modal" id="CourtModal" tabindex="-1" aria-labelledby="OrganizeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="AuthModalLabel">Court Construction</h5>
        <button type="button" class="btn-close" id="CourtModalClose" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
            <div class="login-form">
              <form @submit.prevent="submitCourtConstructionForm">
                <p id="error">{{error_message}}</p>
                <div class="row form-group" v-if="token == null">
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Name" v-model="form2.name">
                  </div>
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Phone" v-model="form2.phone">
                  </div>
                </div>
                <div class="mb-3 icon-input" v-if="token == null">
                  <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Email" v-model="form2.email">
                </div>
                <div class="mb-3 icon-input">
                  <GMapAutocomplete
                  placeholder="Type your location"
                  @place_changed="setPlaces"
                  class="form-control"
                  :value = "form2.location"
                  :options="{
                      componentRestrictions: {'country': ['ae']}
                  }"
                  >
                  </GMapAutocomplete>
                  <i class='icon bx bx-current-location'></i>
                </div>
                <!--<div class="row form-group">
                  <div class="mb-3 col-md-6">
                    <select class="form-select" aria-label="Default select example">
                      <option selected>Brand</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                  <div class="mb-3 col-md-6">
                    <select class="form-select" aria-label="Default select example">
                      <option selected>String Type</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>
                <div class="row form-group">
                  <div class="mb-3 col-md-6">
                    <select class="form-select" aria-label="Default select example">
                      <option selected>Color</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                  <div class="mb-3 col-md-6">
                    <select class="form-select" aria-label="Default select example">
                      <option selected>Tension</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>-->
                <div class="mb-3">
                  <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Your Requirements..." rows="4" v-model="form2.requirements"></textarea>
                </div>
                  <!--<p class="text-foot">Free Grip Change for Pro 65 /BG 65 string</p>-->
                <!--<a href="#" data-bs-toggle="modal" data-bs-target="#SuccessModal">-->
                <button class="btn btn-primary login-btn" type="submit">
                  <span v-if="!showLoad">
                    Submit <i class="bx bx-right-arrow-alt"></i>
                  </span>
                  <div v-if="showLoad" class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
                <!--</a>-->
              </form>
            </div>
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
    </div>
  </div>
</div>

  <!-- Success Modal -->
<div class="modal fade auth-modal success-modal" id="SuccessModal" tabindex="-1" aria-labelledby="OrganizeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
            <div class="message-content">
              <img class="vector" src="../assets/success.png" alt="">
              <p class="text">Thanks for contacting us, our expert team will contact you soon. </p>
              <a href="#"   data-bs-dismiss="modal" aria-label="Close" class="btn btn-primary login-btn">OK <i class="bx bx-right-arrow-alt"></i></a>
            </div>
      </div>
    </div>
  </div>
</div>


</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import HotProduct from '@/components/HotProduct.vue'
import ProductCarousel from '@/components/ProductCarousel.vue'
// import brandsCarousel from '@/components/brandsCarousel.vue'
import axios from 'axios'
// import {ref} from 'Vue'

  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue';
  
   // import styles bundle
  import 'swiper/swiper-bundle.min.css';
  // Import Swiper styles
  // import 'swiper/swiper.min.css';
  
// import Swiper core and required modules
import SwiperCore, {
  Autoplay,Pagination,Navigation
} from 'swiper';

// install Swiper modules
SwiperCore.use([Autoplay,Pagination,Navigation]);

// import {mapMutations } from 'vuex';
// import {
//     LOADING_SPINNER_SHOW_MUTATION,
// } from '../store/storeconstants';
export default {
  name: 'Home',
  components: {
      Swiper,
      SwiperSlide,
      HotProduct,
      ProductCarousel,
      // brandsCarousel
  },
  data (){

    return{
      showLoad: false,
      showLoading: false,
      date: new Date(),
      slides: [],
      bestSelling :[],
      hotProducts: [],
      categories: [],
      base_url:'https://admin.badmintonsouq.com',
      latitude:'',
      longitude:'',
      form:{
        name:'',
        phone:'',
        email:'',
        user_id:'',
        location:'',
        comments:'',
        image:'',
        imageName:'',
        imageDisplay:''
      },
      form2:{
        name:'',
        phone:'',
        email:'',
        user_id:'',
        location:'',
        requirements:''
      },
      token:'',
      error_message:''
    }
  },
  mounted(){
    this.token = this.$store.state.auth.token
    if(this.token){
      this.form.user_id = this.$store.state.auth.user.user_id
      this.form2.user_id = this.$store.state.auth.user.user_id
    }
  },
  created(){
    this.getHomeData()
    this.currentLocation()
  },
  methods:{
    currentLocation(){
      if(this.$store.state.auth.address == null)
      {
        this.latitude  = this.$store.state.auth.latitude
        this.longitude = this.$store.state.auth.longitude
        axios.get(
            "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
              this.latitude +
              "," +
              this.longitude +
              "&key=AIzaSyC9K84fNw6iBAmoUa3OD_w4YOqDRNx7Tvo"
          )
          .then((response) => {
            this.form.location = response.data.results[0].address_components[1].long_name
          })
          .catch(function (error) {
            console.log(error);
        })
      }
      else{
        this.form.location = this.$store.state.auth.address
      }
    },
        // ...mapMutations({
        //     showLoading: LOADING_SPINNER_SHOW_MUTATION,
        // }),
    async getHomeData(){
      // this.showLoading(true);
      this.showLoading = true;
      await axios.post('https://admin.badmintonsouq.com/api/v1/get-dashboard', {})
      .then((response) => {
          this.bestSelling = response.data.data.best_selling
          this.slides      = response.data.data.banners
          this.categories  = response.data.data.categories
          this.hotProducts = response.data.data.hot_collections
      })
      .catch(function (error) {
          console.log(error);
          // this.showLoading(false);
          this.showLoading = false;
      })
      // this.showLoading(false);
      this.showLoading = false;
    },
    pickFile () {
        this.$refs.image.click ()
    },
		
		onFilePicked (e) {
			const files = e.target.files
			if(files[0] !== undefined) {
				this.form.imageName = files[0].name
				if(this.form.imageName.lastIndexOf('.') <= 0) {
					return
				}
				const fr = new FileReader ()
				fr.readAsDataURL(files[0])
				fr.addEventListener('load', () => {
					this.form.image = e.target.files[0]
          this.form.imageDisplay = fr.result
				})
			} else {
				this.form.imageName = ''
				this.form.image = ''
        this.form.imageDisplay = ''
			}
		},
    submitBatRepairForm(){
      this.showLoad = true
      let fd= new FormData()
      fd.append('image', this.form.image)
      fd.append('name', this.form.name)
      fd.append('phone', this.form.phone)
      fd.append('email', this.form.email)
      fd.append('location', this.form.location)
      fd.append('comments', this.form.comments)
      fd.append('imageName', this.form.imageName)
      fd.append('user_id',this.form.user_id)
      axios.post('https://admin.badmintonsouq.com/api/v1/create-bat-repair', fd)
      .then((response) => {
          this.showLoad = false
          if(response.data.success == false){
            this.error_message = response.data.message
          }else{
            this.$swal.fire(response.data.message,'',"success")
            .then(() => {
              document.getElementById("RepairModalClose").click();
              this.form.name = ''
              this.form.phone = ''
              this.form.email = ''
              this.form.location = ''
              this.form.comments = ''
              this.form.image = ''
              this.form.imageName = ''
              this.form.imageDisplay = ''
              // this.$router.go(0);
            })
          }
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    submitCourtConstructionForm(){
      this.showLoad = true
      axios.post('https://admin.badmintonsouq.com/api/v1/create-court-construction', this.form2)
      .then((response) => {
          this.showLoad = false
          if(response.data.success == false){
            this.error_message = response.data.message
          }else{
            this.$swal.fire(response.data.message,'',"success")
            .then(() => {
              document.getElementById("CourtModalClose").click();
              this.form2.name = ''
              this.form2.phone = ''
              this.form2.email = ''
              this.form2.location = ''
              this.form2.requirements = ''
            })
          }
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    setPlaces(place)
    {
      this.form.location  = place.formatted_address
      this.form2.location  = place.formatted_address
    }
  },
}
</script>
<style>
#error{
  color:red;
}
</style>