<template>
  <!-- <div>
      <h1>This is a Header</h1>
        <div id="nav">
            <router-link to="/">Home</router-link> |
            <router-link to="/about">About</router-link>
            <router-link to="/about">About</router-link>
            <router-link to="/about">About</router-link>
            <router-link to="/about">About</router-link>
            <router-link to="/about">About</router-link>
            <router-link to="/about">About</router-link>
        </div>
  </div> -->
  <header class="main-header">
      <div class="container">
        <div class="top-header-sec">
          <div class="nav-sec">
              <!-- <ul>
                  <li class="active">
                      <a href="#"><i class='bx bxs-home'></i></a>
                  </li>
                  <li><a href="#">Mens</a></li>
                  <li><a href="#">junior</a></li>
                  <li><a href="#">Women</a></li>
                  <li><a href="#">Brands</a></li>
                  <li><a href="#">Offers</a></li>
                  <li><a href="#">Help</a></li>
              </ul> -->
            
            <div class="lang-box mob-only" :title="address" data-bs-toggle="modal" data-bs-target="#LocModal">
              <img src=".././assets/uae.png" alt="" class="icon">
              <span>{{address}}</span>
            </div>
            <div class="menu-wrap-mob" id="meuElement"
            @click="showMobNav"
            :class="{ active: isActiveMenu }">
            <div class="nav-trigger mob-only">
              <i class='bx bx-menu'></i>
            </div>
            <div id="nav" class="nav-ul">
                <router-link class="nav-link" to="/"><i class='bx bxs-home'></i></router-link>
                <a class="nav-link" :href="$router.resolve({path: '/main-category/mens'}).href">MENS</a>
                <a class="nav-link" :href="$router.resolve({path: '/main-category/junior'}).href">JUNIOR</a>
                <a class="nav-link" :href="$router.resolve({path: '/main-category/women'}).href">WOMEN</a>
                <router-link class="nav-link" to="/brands">BRANDS</router-link>
                <a class="nav-link" :href="$router.resolve({path: '/clearance'}).href">Offers</a>
                <router-link class="nav-link" to="/contact">HELP</router-link>
            </div>

            </div>

          </div>
          <div class="logo-sec">
                <router-link class="" to="/">
              <img src=".././assets/logo.png" alt=""></router-link>
          </div>
          <div class="actions-sec">
            <div class="mob-only search-trigger" id="searchTrigger"
            @click="showMobSearch"
            >
                <i class='bx bx-search' ></i>
            </div>
            <div class="search-box-wrap" id="searchElement"
            :class="{ active: isActiveSearch }">
              <div class="search-box">
                <i class='bx bx-search' ></i>
                <input class="form-control" type="text" v-model="search" v-on:keyup="getItems" placeholder="Search Products" aria-label="default input example">
              </div>
              <!--<div class="panel-footer autocomplete-box col-xl-6">
                  <ul class="list-group">
                    <li v-for="(item,index) in items.slice(0, 5)" :key="index" @click="selectItem(item)" class="list-group-item autocomplete-box-li">
                        {{ item.title }}
                    </li>
                  </ul>
              </div>-->
              <div class="search-result" v-if="searchTrue">
                
                <ul class="lists">
                  <h4 class="title">Products</h4>
                  <li v-for="(item,index) in items.slice(0, 7)" :key="index" @click="selectItem(item)" class="list">
                    <div class="product-card">
                      <div class="pro-img">
                        <img :src="item.image" alt="" class="images">
                      </div>
                      <div class="pro-info">
                        <span class="brand">{{ item.brand.title }}</span>
                        <p class="pname">{{ item.title }}</p>
                        <div class="price">
                          <p class="single-price old-p">AED {{ item.mrp }}</p>
                          <p class="single-price new-p">AED {{ item.sale_cost }}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div v-if="token == null">
              <a href="#" class="btn btn-primary auth-trigger-btn" title="Login/Sign up" data-bs-toggle="modal" data-bs-target="#AuthModal">Login / Sign Up</a>
            </div>
            <div v-else class="d-flex">
              <a href="/account" class="btn btn-primary" title="My Account">My Account</a>
              <a @click.prevent="logout" class="btn btn-primary logout-btn">Logout</a>
            </div>
            <div class="lang-box desk-only" :title="address" data-bs-toggle="modal" data-bs-target="#LocModal">
              <img src=".././assets/uae.png" alt="" class="icon">
              <span>{{address}}</span>
            </div>
            <div v-if="token != null">
              <router-link class="cart-icon-btn" to="/cart">
                <span class="count">{{$store.state.auth.cart_count}}</span>
                <i class='bx bxs-cart-alt'></i>
              </router-link>
            </div>
            <div v-else>
              <a href="#" title="cart" class="cart-icon-btn" data-bs-toggle="modal" data-bs-target="#AuthModal">
                <span class="count">{{cart_count}}</span>
                <i class='bx bxs-cart-alt'></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="header-bottom">
        <div class="container">
          <div class="features-wrap">
            <div class="feature-item">
              <i class='bx bxs-tennis-ball' ></i>
              <span class="text">Genuine Products</span>
            </div>
            <div class="feature-item">
              <i class='bx bxs-truck'></i>
              <span class="text">Free Delivery</span>
            </div>
            <div class="feature-item hidden">
              <i class='bx bxs-tennis-ball' ></i>
              <span class="text">Branded Products</span>
            </div>
            <div class="feature-item">
              <i class='bx bx-revision' ></i>
              <span class="text">24x7 Support</span>
            </div>
            <div class="feature-item">
              <i class='bx bx-support' ></i>
              <span class="text">14 Days Return</span>
            </div>
          </div>
        </div>
      </div>
  </header>
  <!-- Modal -->
<div class="modal fade auth-modal" id="AuthModal" tabindex="-1" aria-labelledby="AuthModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- <div class="modal-header">
        <h5 class="modal-title" id="AuthModalLabel">Modal title</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body"> -->
        <ul class="nav nav-tab mb-3" id="new-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="Login-tab" data-bs-toggle="pill" data-bs-target="#Login" type="button" role="tab" aria-controls="new-home" aria-selected="true">Login</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="Register-tab" data-bs-toggle="pill" data-bs-target="#Register" type="button" role="tab" aria-controls="new-profile" aria-selected="false">Register</button>
          </li>
        </ul>
        <div class="tab-content" id="new-tabContent">
          <div class="tab-pane fade show active" id="Login" role="tabpanel" aria-labelledby="Login-tab">
            <div class="login-form">
              <form @submit.prevent="loginSubmit" v-if="login">
                <p id="error">{{login_error_message}}</p>
                <div class="mb-3">
                  <label for="phone" class="form-label">Phone</label>
                  <div id="input-wrapper">
                    <label for="number">+971</label>
                    <input type="number" class="form-control" id="number" v-model="form.user_phone" @input="handleInput">
                  </div>
                </div><br><br>
                <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label">Password</label>
                  <input type="password" class="form-control" id="exampleFormControlInput1" v-model="form.password">
                </div>
                <button class="btn btn-primary login-btn" type="submit">Login</button>
                <a href="#" @click.prevent="loginWithOTPForm" title="Login with otp" class="link">Login with OTP</a><br>
                <a href="#" @click.prevent="passwordForm" class="link" title="forgot password">Forgot Password?</a>
              </form>
              <form @submit.prevent="loginOTPSubmit" v-if="loginOTP">
                <p id="error">{{loginOTP_error_message}}</p>
                <div class="mb-3">
                  <label for="phone-no" class="form-label">Mobile Number</label>
                  <div id="input-wrapper">
                    <label for="num">+971</label>
                    <input type="number" class="form-control" id="num" placeholder="" v-model="form2.user_phone" @input="handleInputs2" required>
                  </div>
                </div><br><br>
                <button class="btn btn-primary">
                  <span v-if="!showLoad">
                    Send OTP
                  </span>
                  <div v-if="showLoad" class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
              </form>
              <form @submit.prevent="loginVerifyOTPSubmit" v-if="loginVerifyOTP">
                <p id="error">{{loginVerifyOTP_error}}</p>
                <div class="mb-3">
                  <label for="phone-no" class="form-label">OTP</label>
                  <input type="text" class="form-control" placeholder="Enter a avalid OTP" v-model="form2.otp" required>
                </div>
                <p v-if="login_otp_feed" style="color:green;">{{ login_otp_feed_message }}</p>
                <button class="btn btn-primary">
                  <span v-if="!showLoad">
                    Verify OTP
                  </span>
                  <div v-if="showLoad" class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>&nbsp;
                  <button class="btn btn-primary" @click.prevent="resendLoginOTP">
                    <span v-if="!showLoad">
                      Resend OTP
                    </span>
                    <div v-if="showLoad" class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
              </form>
              <form @submit.prevent="forgotPasswordSubmit" v-if="password">
                <p id="error">{{password_error_message}}</p>
                <div class="mb-3">
                  <label for="phone-no" class="form-label">Mobile Number</label>
                  <div id="input-wrapper">
                    <label for="num">+971</label>
                    <input type="number" class="form-control" id="num" placeholder="" v-model="form1.user_phone" @input="handleInputs" required>
                  </div>
                  <p v-if="feed">{{ message }}</p>
                </div><br><br>
                <button class="btn btn-primary" v-if="send_otp" @click.prevent="sendPasswordOTP">
                  <span v-if="!showLoad">
                    Send OTP
                  </span>
                  <div v-if="showLoad" class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
                <div class="mb-3" v-if="verify_otp">
                  <label for="exampleFormControlInput1" class="form-label">Verify OTP</label>
                  <input type="number" class="form-control" id="exampleFormControlInput1" v-model="otp">
                  <p v-if="otp_feed">{{ message }}</p>
                  <br>
                  <button class="btn btn-primary" @click.prevent="verifyPasswordOTP">
                    <span v-if="!showLoad">
                      Verify
                    </span>
                    <div v-if="showLoad" class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>&nbsp;
                  <button class="btn btn-primary" @click.prevent="resendPasswordOTP">
                    <span v-if="!showLoad">
                      Resend OTP
                    </span>
                    <div v-if="showLoad" class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                </div>
                <div class="mb-3" v-if="verified">
                  <label for="exampleFormControlInput1" class="form-label">New Password</label>
                  <input type="password" class="form-control" id="exampleFormControlInput1" v-model="form1.password" required>
                </div>
                <div class="mb-3" v-if="verified">
                  <label for="exampleFormControlInput1" class="form-label">Confirm Password</label>
                  <input type="password" class="form-control" id="exampleFormControlInput1" v-model="form1.confirm_password" required>
                </div>
                <button class="btn btn-primary login-btn" type="submit" v-if="verified" data-bs-dismiss="modal">
                  <span v-if="!showLoad">
                    Submit
                  </span>
                  <div v-if="showLoad" class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
              </form>
            </div>
          </div>
          <div class="tab-pane fade" id="Register" role="tabpanel" aria-labelledby="Register-tab">
            <div class="login-form">
              <form @submit.prevent="submit">
                {{error_message}}
                <div class="mb-3">
                  <label for="user_phone" class="form-label">Mobile Number</label>
                  <div id="input-wrapper">
                    <label for="numbers">+971</label>
                    <input type="number" class="form-control" id="numbers" placeholder="" v-model="form.user_phone" @input="handleInput" required>
                  </div>
                  <p v-if="feed">{{ message }}</p>
                </div><br><br>
                <button class="btn btn-primary" v-if="send_otp" @click.prevent="sendOTP">
                  <span v-if="!showLoad">
                    Send OTP
                  </span>
                  <div v-if="showLoad" class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
                <div class="mb-3" v-if="verify_otp">
                  <label for="exampleFormControlInput1" class="form-label">Verify OTP</label>
                  <input type="number" class="form-control" id="exampleFormControlInput1" v-model="otp">
                  <p v-if="otp_feed">{{ message }}</p>
                  <br>
                  <button class="btn btn-primary" @click.prevent="verifyOTP">
                    <span v-if="!showLoad">
                      Verify
                    </span>
                    <div v-if="showLoad" class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>&nbsp;
                  <button class="btn btn-primary" @click.prevent="resendOTP">
                    <span v-if="!showLoad">
                      Resend OTP
                    </span>
                    <div v-if="showLoad" class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                </div>
                <div class="mb-3" v-if="verified">
                  <label for="exampleFormControlInput1" class="form-label">Full Name</label>
                  <input type="text" class="form-control" id="exampleFormControlInput1" v-model="form.name" required>
                </div>
                <div class="mb-3" v-if="verified">
                  <label for="exampleFormControlInput1" class="form-label">Email Address</label>
                  <input type="email" class="form-control" id="exampleFormControlInput1" v-model="form.email" placeholder="name@example.com" required>
                </div>
                <div class="mb-3" v-if="verified">
                  <label for="exampleFormControlInput1" class="form-label">Password</label>
                  <input type="password" class="form-control" id="exampleFormControlInput1" v-model="form.password" required>
                </div>
                <button class="btn btn-primary login-btn" v-if="verified" type="submit">
                  <span v-if="!showLoad">
                    Register
                  </span>
                  <div v-if="showLoad" class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
              </form>
            </div>
          </div>
        </div>
      <!-- </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
    </div>
  </div>
</div>



<!-- Location Modal -->
<div class="modal fade auth-modal organize-modal" id="LocModal" tabindex="-1" aria-labelledby="LocModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="AuthModalLabel">Choose your Location</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="login-form">
          <form>
            <div class="mb-3">
              <label for="LocaText" class="form-label">Type your location</label>
              <GMapAutocomplete
                  placeholder="Type your location"
                  @place_changed="setPlace"
                  class="form-control"
                  :value = "address"
                  :options="{
                      componentRestrictions: {'country': ['ae']}
                  }"
                >
              </GMapAutocomplete>
            </div>
            <button class="btn btn-primary login-btn" @click.prevent="updateLocation" type="submit" data-bs-dismiss="modal" >Submit</button>
          </form>
        </div>
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
    </div>
  </div>
</div>
</template>

<script>

import axios from 'axios'
import { mapActions } from "vuex";
export default {
  name: 'Header',
  data (){

    return{
      isActiveMenu: false,
      isActiveSearch: false,
      message:'',
      otp:'',
      send_otp:true,
      verify_otp:false,
      feed:false,
      verified:false,
      otp_feed:false,
      showLoad:false,
      form:{
        user_id:'',
        name:'',
        email:'',
        password:'',
        phone: '',
        user_phone:''
      },
      form1:{
        password:'',
        confirm_password:'',
        phone:'',
        user_phone:''
      },
      form2:{
        otp:'',
        phone:'',
        user_phone:''
      },
      token:'',
      cart_count:0,
      Search:'',
      item_id:'',
      items:[],
      item_slug:'',
      latitude:'',
      longitude:'',
      address:'Dubai',
      login_error_message:'',
      searchTrue:false,
      login:true,
      loginOTP:false,
      loginVerifyOTP:false,
      loginOTP_error_message:'',
      loginVerifyOTP_error:'',
      password:false,
      password_error_message:'',
      location: '',
      position:'',
      login_otp_feed:false,
      login_otp_feed_message:''
    }
  },
  mounted(){
    this.token = this.$store.state.auth.token;
    // var ignoreClickOnMeElement = document.getElementById('meuElement');
    // // var ignoreClickOnMeElementSearch = document.getElementById('searchElement');
    // // var searchTrigger = document.getElementById('searchTrigger');
    // document.addEventListener('click', function(event) {
    //     var isClickInsideElement = ignoreClickOnMeElement.contains(event.target);
    //     // var isClickInsideElementSearch = ignoreClickOnMeElementSearch.contains(event.target);
    //     if (!isClickInsideElement) {
    //         ignoreClickOnMeElement.click();
    //     }
    // });
  },
  created(){
    this.getCartCount();
    this.getArea();
  },
  methods:{
    ...mapActions(["SetUserLoc"]),
    ...mapActions(["SetPosition"]),
    ...mapActions(["CartCount"]),
    showMobNav() {
      this.isActiveMenu = !this.isActiveMenu;
      this.isActiveSearch = false;
        setTimeout(function(){
          this.isActiveMenu = false;
        },3000);
    },
    showMobSearch() {
      this.isActiveSearch = !this.isActiveSearch;
      this.isActiveMenu = false;
        setTimeout(function(){
          this.isActiveSearch = false;
        },5000);
    },
    setPlace(place)
    {
      this.position = place.geometry.location
      this.address  = place.address_components[1].long_name
    },
    getArea(){
      if(this.$store.state.auth.address == null)
      {
        this.latitude  = this.$store.state.auth.latitude
        this.longitude = this.$store.state.auth.longitude
        try {
          axios.get(
            "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
              this.latitude +
              "," +
              this.longitude +
              "&key=AIzaSyC9K84fNw6iBAmoUa3OD_w4YOqDRNx7Tvo"
          )
          .then((response) => {
            this.address = response.data.results[0].address_components[1].long_name
          })
          .catch(function (error) { 
              console.log(error);
          })
        } 
        catch (error) {
          console.log(error.message);
        }
      }
      else
      {
        this.address = this.$store.state.auth.address
      }
    },
    updateLocation()
    {
      this.SetUserLoc(this.address)
      this.SetPosition(this.position)
      // this.$swal.fire('Location Changed','',"success")
      // .then(() => {
      //   this.$router.go(0);
      // })
    },
    passwordForm() 
    {
      this.login = false
      this.password = true
    },
    loginWithOTPForm(){
      this.login = false
      this.loginOTP = true
    },
    selectItem(item){
        this.search = item.title
        this.item_id = item.id
        this.item_slug = item.slug
        this.items = []
        window.location.href= '/products/'+this.item_slug
    },
    getItems(){
        if(this.search.length == 0){
            this.items = [];
        }
        if(this.search.length > 0){
            axios.post('https://admin.badmintonsouq.com/api/v1/search-item',{
              search: this.search
            })
            .then(response => {
                this.items = response.data.data.items;
                if(this.items.length > 0){
                  this.searchTrue = true
                }
                
            })
            .catch(function (error) {
              console.log(error);
            });
        }
    },
    getCartCount(){
      this.token = this.$store.state.auth.token
      if(this.token != null)
      {
        axios.post('https://admin.badmintonsouq.com/api/v1/cart-count',{}, {
          headers: {
              Authorization  : 'Bearer ' + this.token
          }
        })
        .then((response) => {
            this.CartCount(response.data.data.count)
        })
        .catch(function (error) {
            console.log(error);
        })
      }
    },
    sendOTP(){
      this.showLoad = true;
      axios.post('https://admin.badmintonsouq.com/api/v1/send-otp', {
        phone:this.form.phone
      })
      .then((response) => {
          this.feed = true
          this.message  = response.data.message
          this.showLoad = false;
          if(response.data.success == true)
          {
            this.send_otp = false
            this.verify_otp = true
          }
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    resendOTP(){
      this.showLoad = true;
      axios.post('https://admin.badmintonsouq.com/api/v1/resend-otp', {
        phone:this.form.phone
      })
      .then((response) => {
          this.feed = false
          this.otp_feed = true
          this.message  = response.data.message
          this.showLoad = false;
          if(response.data.success == true)
          {
            this.send_otp = false
            this.verify_otp = true
          }
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    verifyOTP(){
      this.otp_feed = false
      this.showLoad = true;
      axios.post('https://admin.badmintonsouq.com/api/v1/register-otp-validate', {
        phone:this.form.phone,
        otp:this.otp
      })
      .then((response) => {
          this.message  = response.data.message
          this.showLoad = false;
          this.form.user_id  = response.data.data.user.user_id
          if(response.data.success == true)
          {
            this.feed = true
            this.verify_otp = false
            this.verified = true
          }
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    sendPasswordOTP(){
      this.showLoad = true;
      axios.post('https://admin.badmintonsouq.com/api/v1/send-password-otp', {
        phone:this.form1.phone
      })
      .then((response) => {
          this.feed = true
          this.message  = response.data.message
          this.showLoad = false
          if(response.data.success == true)
          {
            this.send_otp = false
            this.verify_otp = true
          }
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    resendPasswordOTP(){
      this.showLoad = true;
      axios.post('https://admin.badmintonsouq.com/api/v1/resend-password-otp', {
        phone:this.form1.phone
      })
      .then((response) => {
          this.feed = false
          this.otp_feed = true
          this.message  = response.data.message
          this.showLoad = false;
          if(response.data.success == true)
          {
            this.send_otp = false
            this.verify_otp = true
          }
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    resendLoginOTP(){
      this.showLoad = true;
      this.login_otp_feed_message  = 'OTP Sending';
      axios.post('https://admin.badmintonsouq.com/api/v1/resend-otp', {
        phone:this.form2.phone
      })
      .then((response) => {
          this.login_otp_feed = true
          this.login_otp_feed_message  = response.data.message
          this.loginVerifyOTP_error = ""
          this.showLoad = false;
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    verifyPasswordOTP(){
      this.otp_feed = false
      this.showLoad = true;
      axios.post('https://admin.badmintonsouq.com/api/v1/password-otp-validate', {
        phone:this.form1.phone,
        otp:this.otp
      })
      .then((response) => {
          this.message  = response.data.message
          this.showLoad = false;
          if(response.data.success == true)
          {
            this.feed = true
            this.verify_otp = false
            this.verified = true
          }
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    forgotPasswordSubmit(){
      this.showLoad = true;
      axios.post('https://admin.badmintonsouq.com/api/v1/update-password', this.form1)
      .then((response) => {
          this.showLoad = false;
          if(response.data.success == false){
            this.password_error_message = response.data.message
          }else{
            this.$swal.fire(response.data.message,'',"success")
            // .then(() => {
            //   this.$router.go(0);
            // })
          }
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    loginOTPSubmit(){
      this.showLoad = true;
      axios.post('https://admin.badmintonsouq.com/api/v1/login-otp', this.form2)
      .then((response) => {
          this.showLoad = false;
          if(response.data.success == false){
            this.loginOTP_error_message = response.data.message
          }else{
            this.login_otp_feed = true
            this.login_otp_feed_message = response.data.message
            this.loginVerifyOTP = true
            this.loginOTP = false
          }
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    ...mapActions(["OTPLogin"]),
    async loginVerifyOTPSubmit() {
      const User = new FormData();
      User.append("otp", this.form2.otp);
      User.append("phone", this.form2.phone);
      User.append("device_id", 'AFhd78578');
      User.append("os", 'Desktop');
      try {
          await this.OTPLogin(User);
          if(this.$store.state.auth.token == null)
          {
            this.login_otp_feed_message = ""
            this.loginVerifyOTP_error = "Oops!, Invalid OTP"
          }
          else{
            this.$router.push("/");
            this.$router.go(0);
          }
      } catch (error) {
        console.log(error);
      }
    },
    ...mapActions(["Register"]),
    async submit() {
      try {
        this.showLoad = true
        await this.Register(this.form);
        this.$swal.fire("Done", "You are successfully registered!","success")
        .then(() => {
          this.$router.push("/");
          this.$router.go(0);
        })
      } catch (error) {
        console.log(error);
      }
    },
    ...mapActions(["LogIn"]),
    async loginSubmit() {
      const User = new FormData();
      User.append("phone", this.form.phone);
      User.append("password", this.form.password);
      try {
          await this.LogIn(User);
          if(this.$store.state.auth.token == null)
          {
            this.login_error_message = "Oops!, Please check your credentials"
          }
          else{
            this.$router.push("/");
            this.$router.go(0);
          }
      } catch (error) {
        console.log(error);
      }
    },
    ...mapActions(["LogOut"]),
    async logout() {
      try {
          await this.LogOut(this.$store.state.auth.user);
          this.$router.push("/");
          this.$router.go(0);
      } catch (error) {
        console.log(error);
      }
    },
    handleInput()
    {
      this.form.phone = '+971'+this.form.user_phone
    },
    handleInputs()
    {
      this.form1.phone = '+971'+this.form1.user_phone
    },
    handleInputs2()
    {
      this.form2.phone = '+971'+this.form2.user_phone
    }
  }
}
</script>

<style>
#error{
  color:red;
}
#input-wrapper * {
  position: absolute;
}

#input-wrapper label {
  z-index: 99;
  line-height: 41px;
  padding: 2px;
  margin-left: 10px;
}

#input-wrapper input {
  text-indent: 35px;
  width: 88%;
}
</style>