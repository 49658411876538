import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
import auth from './modules/auth';
import { LOADING_SPINNER_SHOW_MUTATION } from './storeconstants';

// Create store
export default new Vuex.Store({
  modules: {
    auth
  },
  state() {
      return {
          showLoading: false,
      };
  },
  mutations: {
      [LOADING_SPINNER_SHOW_MUTATION](state, payload) {
          state.showLoading = payload;
      },
  },
  plugins: [createPersistedState()]
});