<template>
    <PageLoader  v-if="showLoading" />
    <Header/>
  <router-view/>
    <Footer/>
</template>
<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import PageLoader from './components/PageLoader'
import { mapActions, mapState } from "vuex";
import axios from 'axios'
export default {
  name: 'Home',
  
    computed: {
        ...mapState({
            showLoading: (state) => state.showLoading,
        }),
    },
  components: {
    Header,
    Footer,
    PageLoader
  },
    // data: () => {
    //   return {
    //     isloaded: false
    //   }
    // },
  created(){
    this.getlocation()
  },
  methods:{
    ...mapActions(["SetLoc"]),
    getlocation(){
      if(this.$store.state.auth.latitude == null && this.$store.state.auth.longitude == null)
      {
        const success = (position) => {
          try {
            axios.get(
            "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
              position.coords.latitude +
              "," +
              position.coords.longitude +
              "&key=AIzaSyC9K84fNw6iBAmoUa3OD_w4YOqDRNx7Tvo&sensor=false&components=country:ae"
            )
            .then((response) => {
              this.SetLoc(response.data.results[0].geometry.location)
            })
            .catch(function (error) {
              console.log(error);
            })
          } 
          catch (error){
            console.log(error);
          }
        };
        const error = (error) => {
            console.log(error)
        };
        // This will open permission popup
        navigator.geolocation.getCurrentPosition(success, error);
      }
    }
  }
}
</script>
<style>
  @import '/boxicons.min.css';
  @import './assets/main.css';
</style>
