import axios from 'axios';
const state = {
    user: null,
    token: null,
    latitude: null,
    longitude: null,
    address:null,
    cart_count:0
};
const getters = {
    isAuthenticated: state => !!state.user, 
    StateUser: state => state.user,
    StateToken: state => state.token,
    StateLatitude: state => state.latitude,
    StateLongitude: state => state.longitude,
    StateAddress: state => state.address,
    StateCartCount: state => state.cart_count
};
const actions = {
    async Register({dispatch}, form) {
        await axios.post('https://admin.badmintonsouq.com/api/v1/register-user', form)
        .then((response) => {
          console.log(response)
        })
        .catch(function (error) {
            console.log(error);
        })
        let UserForm = new FormData()
        UserForm.append('phone', form.phone)
        UserForm.append('password', form.password)
        await dispatch('LogIn', UserForm)
      },
      async LogIn({commit}, User) {
        await axios.post('https://admin.badmintonsouq.com/api/v1/login', User)
        .then((response) => {
          if(response.data.success == true){
            commit('setToken', response.data.data.token)
            commit('setUser', response.data.data.user)
          }
        })
        .catch(function (error) {
            console.log(error);
        })
      },
      async OTPLogin({commit}, User) {
        await axios.post('https://admin.badmintonsouq.com/api/v1/verify-otp', User)
        .then((response) => {
          if(response.data.success == true){
            commit('setToken', response.data.data.token)
            commit('setUser', response.data.data.user)
          }
        })
        .catch(function (error) {
            console.log(error);
        })
      },
      async LogOut({commit}){
        let user = null
        commit('LogOut', user)
      },
      async SetLoc({commit},position){
        commit('setLocation', position)
      },
      async SetPosition({commit},position){
        commit('setPos', position)
      },
      async SetUserLoc({commit},location){
        commit('setUserLocation', location)
      },
      async CartCount({commit},cart_count){
        commit('setCartCount', cart_count)
      }
};
const mutations = {
      setUser(state, user){
        state.user = {
          'user_id': user.user_id,
          'name' : user.name,
          'phone' : user.phone,
          'email' : user.email
        }
    },
    setToken(state, token){
        state.token = token
    },
    LogOut(state){
        state.user  = null
        state.token = null
    },
    setLocation(state, position){
      state.latitude  = position.lat
      state.longitude = position.lng
    },
    setPos(state, position){
      state.latitude  = position.lat()
      state.longitude = position.lng()
    },
    setUserLocation(state, location){
      state.address   = location
    },
    setCartCount(state, cart_count){
      state.cart_count   = cart_count
    }
};
export default {
  state,
  getters,
  actions,
  mutations
};
