<template>
<div class="container">
  <div class="special-cat-products">
    <div class="cate-info">
      <h3 class="title">HOT PRODUCT.</h3>
      <p class="text">Clothes with a selection
of tops, sweatshirts, t-shirts, and more</p>
    <!-- <a href="" class="link-btn">Explore All <i class='bx bx-right-arrow-alt'></i></a> -->
    <!--  <router-link class="nav-link link-btn" to="/ProductList">Explore All <i class='bx bx-right-arrow-alt'></i></router-link>-->
    </div>
    <!-- Shimmer Skelton -->
    <swiper v-if="loadingdata"
     :slidesPerView="2" 
     :spaceBetween="10" 
     :autoplay='{
        "delay": 2500,
        "disableOnInteraction": false
      }' 
      :navigation="true"
      :breakpoints='{
        "640": {
          "slidesPerView": 2,
          "spaceBetween": 10
        },
        "768": {
          "slidesPerView": 3,
          "spaceBetween": 20
        },
        "1024": {
          "slidesPerView": 4,
          "spaceBetween": 20
        }
      }' class="product-slider"> 
    <swiper-slide>
    <div class="carousel-item-single">
      <card-shimmer :is-loading="loadingdata" />
    </div>
    </swiper-slide>
    <swiper-slide>
    <div class="carousel-item-single">
      <card-shimmer :is-loading="loadingdata" />
    </div>
    </swiper-slide>
    <swiper-slide>
    <div class="carousel-item-single">
      <card-shimmer :is-loading="loadingdata" />
    </div>
    </swiper-slide>
    <swiper-slide>
    <div class="carousel-item-single">
      <card-shimmer :is-loading="loadingdata" />
    </div>
    </swiper-slide>
  </swiper>
  
    <!-- End Shimmer Skelton -->

    <swiper
     :slidesPerView="2" 
     :spaceBetween="10" 
     :autoplay='{
        "delay": 2500,
        "disableOnInteraction": false
      }' 
      :navigation="true"
      :breakpoints='{
        "640": {
          "slidesPerView": 2,
          "spaceBetween": 10
        },
        "768": {
          "slidesPerView": 3,
          "spaceBetween": 20
        },
        "1024": {
          "slidesPerView": 4,
          "spaceBetween": 20
        }
      }' class="product-slider"> 
    <swiper-slide 
     v-for="(Listdata, index) in Listdata"
     :key="index" 
    >
    <div class="carousel-item-single">
      <div class="pro-img">
        <a :href="$router.resolve({path: '/products/'+Listdata.slug}).href">
        <img :src="Listdata.image" /></a>
        <!-- <span class="offer">{{Listdata.offer}}</span> -->
      </div>
      <div class="pro-info">
        <span class="brand-n">{{Listdata.brand.title}}</span>
        <a :href="$router.resolve({path: '/products/'+Listdata.slug}).href">
          <p class="pro-name" v-if="Listdata.title.length<50">{{Listdata.title}}</p>
          <p class="pro-name" v-if="Listdata.title.length>50">{{Listdata.title.substring(0,50)+"..."}}</p>
        </a>
        <div class="rating" v-if="Listdata.rating != null">
          <star-rating 
          :rating="parseFloat(Listdata.rating)" 
          :read-only="true" 
          :increment="0.5" 
          :star-size="15"
          inactive-color="#E1E1E1" 
          active-color="#A5A5A5"
          :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"></star-rating>
          </div>
        <div class="price">
          <p class="old-p">{{Listdata.mrp}}</p>
          <p class="new-p">{{Listdata.sale_cost}}</p>
        </div>
        <div v-if="Listdata.category.title != 'Shoes' && Listdata.category.title != 'Rackets'">
          <button v-if="token != null" class="btn add-to-cart" @click.prevent="addToCart(Listdata.id)"><i class='bx bxs-cart-alt'></i> Add to Cart</button>
          <button class="btn add-to-cart" v-else data-bs-toggle="modal" data-bs-target="#AuthModal"> <i class='bx bxs-cart-alt'></i> Add to Cart</button>
        </div>
      </div>
    </div>
    </swiper-slide>
  </swiper>
  </div>
  
</div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import StarRating from 'vue-star-rating'
   // import styles bundle
  import 'swiper/swiper-bundle.min.css';
  import axios from 'axios'
  import { mapActions } from "vuex";
  // Import Swiper styles
  // import 'swiper/swiper.min.css';
  
// import Swiper core and required modules
import SwiperCore, {
  Autoplay,Navigation
} from 'swiper';

// install Swiper modules
SwiperCore.use([Autoplay,Navigation]);

export default {
  name: 'HotProduct',
  props:{
    Listdata: {
        type:[Array, Object],
        required: true
    },
    loadingdata:{

    }
  },
  components: {
    // HelloWorld
      Swiper,
      SwiperSlide,
      StarRating
  },
  data (){
    return{
      
      token:'',
      color_id:'',
      size_id:'',
      addon_charge:0.00
    }
  },
  mounted(){
    this.token = this.$store.state.auth.token
  },
  methods:{
    ...mapActions(["CartCount"]),
    getCartCount(){
      this.token = this.$store.state.auth.token
      if(this.token != null)
      {
        axios.post('https://admin.badmintonsouq.com/api/v1/cart-count',{}, {
          headers: {
              Authorization  : 'Bearer ' + this.token
          }
        })
        .then((response) => {
            this.CartCount(response.data.data.count)
        })
        .catch(function (error) {
            console.log(error);
        })
      }
    },
    addToCart(product_id){
      axios.post('https://admin.badmintonsouq.com/api/v1/cart/increment', {
          item_id:product_id,
          color_id: this.color_id,
          size_id: this.size_id,
          addon_charge: this.addon_charge
      },
      {
        headers: {
            Authorization  : 'Bearer ' + this.token
        }
      })
      .then((response) => {
        if(response.data.success == true){
          this.$toast.show(response.data.message,{
            type: 'success',
            position: 'top-right',
            duration: '2000'
          })
          this.getCartCount()
        }else{
          this.$toast.show(response.data.message,{
            type: 'error',
            position: 'top-right',
            duration: '2000'
          })
        }
      })
      .catch(function (error) {
          console.log(error);
      })
    }
  }
}
</script>

<style scoped>
a{
  text-decoration:none;
  color:black;
}
</style>
