<template>
  <div class="footer-wrap">
      <!-- <h1>This is a Footer</h1> -->
      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <div class="single-widget">
              <h4 class="title">Browse</h4>
              <ul class="lists">
                <li class="list">
                  <a :href="$router.resolve({path: '/main-category/mens'}).href" class="link">Mens</a>
                </li>
                <li class="list">
                  <a :href="$router.resolve({path: '/main-category/women'}).href" class="link">Women</a>
                </li>
                <li class="list">
                  <a :href="$router.resolve({path: '/main-category/junior'}).href" class="link">Junior</a>
                </li>
                <li class="list">
                  <a href="/brands" title="Brand" class="link">Brands</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="single-widget">
              <h4 class="title">Services</h4>
              <ul class="lists">
                <li class="list">
                  <a href="/coaching-centres" title="Courts & Academies" class="link">Courts & Academies</a>
                </li>
                <li class="list">
                  <a href="/personal-trainers" title="Personal Trainers" class="link">Personal Trainers</a>
                </li>
                <li class="list">
                  <a href="/polling-agents" title="Queuing/Polling" class="link">Queuing/Polling</a>
                </li>
                <li class="list">
                  <a href="/sports-clinics-doctors" title="Sports Clinics & Doctors" class="link">Sports Clinics & Doctors</a>
                </li>
                <li class="list">
                  <a href="/organize" title="Tournament Organizers" class="link">Tournament Organizers</a>
                </li>
                <li class="list">
                  <a href="/events" title="events" class="link">Events</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="single-widget">
              <h4 class="title">Connect</h4>
              <ul class="lists">
                <li class="list">
                  <a href="/clearance" title="Clearence" class="link">Clearence</a>
                </li>
                <li class="list">
                  <a href="/contact" title="Help" class="link">Help</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="single-widget">
              <h4 class="title">Subscribe for Newsletter</h4>
              <p class="text">Sign up for our newsletter and stay up-to-date with the 
                latest collection, the latest trends and the best deals!</p>
                <div class="news-letter-box">
                  <form @submit.prevent="submit">
                    <button class="btn"><i class='bx bxs-send' ></i></button> 
                    <input class="form-control" type="email" placeholder="Your email address.." name="email" v-model="form.email" aria-label="default input example">
                    {{sub_message}}
                  </form>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="copy-note">
        <div class="container">
          <div class="flex-wrap">
            <div class="d-flex align-items-center">
            <p class="copy-text">© 2021 badmintonsouq.com.
              </p>
                <div class="dev">
                  <span>Developed by</span> 
                  <a href="https://appziac.com/" title="Appiac Technologies" target="_blank"><img class="logo-dev" src="../assets/appziac2.png" alt=""></a>
                </div>
              </div>
            <ul class="links-lists">
             <li>
                <a href="https://www.badmintonsouq.com/terms-conditions" title="Terms & Conditions">Terms & Conditions</a>
              </li>
               <li>
                <a href="https://www.badmintonsouq.com/terms-conditions-stringing" title="Stringing Terms & Conditions">Stringing Terms & Conditions</a>
              </li>
              <li>
                <a href="https://www.badmintonsouq.com/terms-conditions" title="Cancellation Policy">Cancellation Policy</a>
              </li>
              <li>
                <a href="https://www.badmintonsouq.com/return-policy" title="Return Policy">Return Policy</a>
              </li>
              <li>
                <a href="https://www.badmintonsouq.com/sitemap.html" title="Sitemap">Sitemap</a>
              </li>
            </ul>
            <ul class="social-links">
              <li class="list">
                <a href="https://twitter.com/badmintonsouq" title="Twitter" class="link" target="_blank">
                  <i class='bx bxl-twitter' ></i>
                </a>
              </li>
              <li class="list">
                <a href="https://www.facebook.com/badmintonsouq" title="Facebook" class="link" target="_blank">
                  <i class='bx bxl-facebook' ></i>
                </a>
              </li>
              <li class="list">
                <a href="https://www.instagram.com/badmintonsouqs/" title="Instagram" class="link" target="_blank">
                  <i class='bx bxl-instagram' ></i>
                </a>
              </li>
              <li class="list">
                <a href="https://www.linkedin.com/company/badmintonsouq" title="Linkedin" class="link" target="_blank">
                  <i class='bx bxl-linkedin'></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
  </div>

  <a href="https://api.whatsapp.com/send?phone=+971505048498&text=Hi, I would like to know more about your services" title="Whatsapp" class="float" target="_blank">
  <i class='bx bxl-whatsapp'></i>
  </a>
  <!-- <div class="back-to-top">
    <i class='bx bx-chevron-up'></i>
  </div> -->
  <div id="pagetop" class="fixed back-to-top" v-show="scY > 300" @click="toTop">
    <i class='bx bx-chevron-up'></i></div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Footer',
    data() {
      return {
        scTimer: 0,
        scY: 0,
        form:{
          email:''
        },
        sub_message:''
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
      submit(){
        axios.post('https://admin.badmintonsouq.com/api/v1/create-subscriber', {
          email: this.form.email
        })
        .then((response) => {
            if(response.data.success == true){
              this.$toast.show(response.data.message,{
                type: 'success',
                position: 'top-right',
                duration: '2000'
              })
              this.sub_message = ''
            }
            else{
              this.sub_message = response.data.message
            }
            this.form.email = ''
        })
        .catch(function (error) {
            console.log(error);
        })
      },
      handleScroll: function () {
        if (this.scTimer) return;
        this.scTimer = setTimeout(() => {
          this.scY = window.scrollY;
          clearTimeout(this.scTimer);
          this.scTimer = 0;
        }, 100);
      },
      toTop: function () {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      },
    },
}
</script>

<style>
.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:70px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
}
.float i.bx.bxl-whatsapp {

    position: relative;
    padding: 2px;
    font-size: 51px;
}
</style>